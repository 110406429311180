import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Buttons'
import { HiMinus, HiPlus } from 'react-icons/hi'
import { FormNumericInput } from 'components/shared'
import { useState } from 'react'
const AddMinusInput = React.forwardRef((props, ref) => {
    const {
        value,
        callback,
        handleValueChange,
        increameent,
        type,
        min,
        name,
        ...rest
    } = props;
    const [currenValue, setCurrentvalue] = useState(value)
    
    const updateChange = (e) =>{
        setCurrentvalue(e.floatValue);
        handleValueChange(e.floatValue,name)
        
    }
    const addValue = ()=>{
        
        setCurrentvalue(type ==="int"?(currenValue+increameent):parseFloat(currenValue+increameent).toFixed(2))
    }
    const minusValue = ()=>{
        if((currenValue-increameent) >= min){
        setCurrentvalue(type ==="int"?(currenValue-increameent):parseFloat(currenValue-increameent).toFixed(2))
    }
    }
  return (
    <div className='flex space-x-0'>
         <Button icon={<HiMinus />} onClick={minusValue} className="!rounded-r-none !border-r-0" />
<FormNumericInput  value={currenValue} onValueChange={updateChange} className="w-16 px-1 text-center focus:ring-slate-200 !rounded-r-none !rounded-l-none" />
<Button icon={<HiPlus />} onClick={addValue}  className="!rounded-l-none !border-l-0" />
    </div>
  )
})

AddMinusInput.propTypes = {
    
   
    className: PropTypes.string,
    
    color: PropTypes.string,
   
    active: PropTypes.bool,
}

AddMinusInput.defaultProps = {
    variant: 'default',
    shape: 'round',
    increameent:.10,
    min:1,
    type:"float",
    active: false,
    loading: false,
    disabled: false,
    color: '',
}

export default AddMinusInput