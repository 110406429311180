import React, { memo, useMemo, lazy, Suspense, useState, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,LAYOUT_TYPE_OPEN,LAYOUT_TYPE_MAIN
} from 'constants/theme.constant'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useQuery from 'utils/hooks/useQuery'
import ApiService from 'services/ApiService'
import appConfig from 'configs/app.config'
import axios from 'axios'
import { setUser } from 'store/auth/userSlice'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_MAIN]: lazy(() => import('./MainLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_OPEN]: lazy(() => import('./OpenLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = (props) => {
    const query = useQuery()
    const [searchParams,setSearchParams] = useSearchParams();
    const location = useLocation();
    const currentpath = location.pathname.split("/").reverse()
    const layoutType = useSelector((state) => state.theme.layout.type)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userInfo = useSelector((state) => state.auth.user);
    const { token } = useSelector((state) => state.auth.session)
   
    const [verfiying, setverfiying] = useState(true)
    // alert(shopename)
    const { authenticated } = useAuth();
    const onboaringsurvey = localStorage.getItem("onboaringsurvey")

    
    // console.log("props",currentpath);
    useDirection()

    useLocale()
    useEffect(() => {
      
        verifyShopfiyLaunch()
      return () => {
        
      }
    }, [])
    const verifyShopfiyLaunch = async()=>{
        const shopename = searchParams.get('shop');
        const code = searchParams.get('code');
        const wixTokeninstance = searchParams.get('instance');
        if(userInfo && userInfo.isGuest){
            
             navigate(
                "/dashboard/product/design"
            )
            
        }
        if(!shopename){
            if(wixTokeninstance){
               
                const tokenUrl = appConfig.apiPrefix+'user/store/verify-wix-instance';
                const requestOptions = {
                    method: 'POST',
                    url: tokenUrl,
                    data: {instance:wixTokeninstance},
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Bearer '+token
                    }
                };
                await axios.request(requestOptions)
                .then((response) => {
                    setverfiying(false)

                })

            }
            else{
                setverfiying(false)

            }
        }
        else if(!authenticated){
            const tokenUrl = appConfig.apiPrefix+'user/shopify-redirect?oauth_verifier=true';
            const requestOptions = {
                method: 'POST',
                url: tokenUrl,
                data: {shop:shopename,code:code},
                headers: {
                    'Content-Type': 'application/json',
                   
                    
                }
            };
            await axios.request(requestOptions)
            .then((response) => {
                if(response.data.IsSuccessful){
                    const { token } = response.data;
                    dispatch(onSignInSuccess(token))
                    if (response.data.user) {
                        dispatch(
                            setUser(
                                response.data.user || {
                                    avatar: '',
                                    userName: 'Anonymous',
                                    authority: ['USER'],
                                    email: '',
                                }
                            )
                        )
                        const redirectUrl = null
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                        setverfiying(false)
                    }

                }
                else{
                    if(response.data.shopifyAuth){
                        const scopes = "write_third_party_fulfillment_orders,write_products,write_orders,read_locations,write_third_party_fulfillment_orders,read_markets,write_fulfillments,read_inventory,write_inventory,write_assigned_fulfillment_orders,read_draft_orders,read_assigned_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_merchant_managed_fulfillment_orders,write_shipping";
                        const forwardingAddress = "https://usprintfactory.com/app/dashboard/stores";
                        const redirectURL = forwardingAddress + "/shopify-auth-callback";
                        const shopState = "shopState";
                        // install url for app install
                        const installUrl =
                        "https://" +
                        shopename +
                        "/admin/oauth/authorize?client_id=" +
                        process.env.REACT_APP_SHOPIFY_API_KEY +
                        "&scope=" +
                        scopes +
                        "&state=" +
                        shopState +
                        "&redirect_uri=" +
                        redirectURL;
                    
                         window.location.href = installUrl;
                    }
                    else{
                        if(response.data.code === 101){
                            localStorage.setItem("tempshopifyid",response.data.tempId);
                            localStorage.setItem("tempshopifyshop",response.data.shop);
                        }
                        setverfiying(false)
                    }
                    
                }
            })
        }
        else{
            if(wixTokeninstance){
               
                const tokenUrl = appConfig.apiPrefix+'user/store/verify-wix-instance';
                const requestOptions = {
                    method: 'POST',
                    url: tokenUrl,
                    data: {instance:wixTokeninstance},
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Bearer '+token
                    }
                };
                await axios.request(requestOptions)
                .then((response) => {
                    // setverfiying(false)

                })

            }
            else{
                setverfiying(false)
            }
            
        }
    }

    const AppLayout = useMemo(() => {
       
        if (authenticated) {
            if(onboaringsurvey){
                return lazy(() => import('./AuthLayout'))
            }
            return layouts[LAYOUT_TYPE_MODERN]
        }
        // return layouts[LAYOUT_TYPE_MAIN]
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    return (
        <Loading loading={verfiying}><Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={verfiying} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
        </Loading>
    )
}

export default memo(Layout)
