import ApiService from './ApiService'

export async function apiSignIn(data) {
    
    return ApiService.fetchData({
        url: 'user/login?oauth_verifier=true',
        method: 'post',
        data,
    })
}
export async function apiExtAuth(data) {
    
    return ApiService.fetchData({
        url: 'user/external-auth?oauth_verifier=true',
        method: 'post',
        data,
    })
}
export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: 'user/register?oauth_verifier=true',
        method: 'post',
        data,
    })
}
export async function apiCompleteOB(data) {
    return ApiService.fetchData({
        url: 'user/complete-ob',
        method: 'post',
        data,
    })
}
export async function apiGuestSession(data) {
    return ApiService.fetchData({
        url: 'user/guest-session?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: 'user/sign-out?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: 'user/forgot-password?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: 'user/reset-password?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiaddRemoveFav(data) {
    return ApiService.fetchData({
        url: 'user/addRemoveFav',
        method: 'post',
        data,
    })
}
