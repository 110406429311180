import React from 'react'
import { Provider } from 'react-redux'
// import { ApolloClient, ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import ReactGA from 'react-ga4';
import appConfig from 'configs/app.config'
import './locales'
// import { ApolloProvider } from 'react-apollo';
// import { ApolloClient } from '@apollo/client';
// import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
// import { ApolloClient, ApolloProvider } from 'react-apollo';

const environment = process.env.NODE_ENV

  
/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    // mockServer({ environment })
}

function App() {
    ReactGA.initialize([{
        trackingId: "G-MBX3PRXMHL",
        gaOptions: {
            name: 'Default Tracker'
        }, // optional
        
      },
      {
        trackingId: "G-W0P0HWHMHP",
        gaOptions: {
            name: 'App Tracker'
        }, // optional
        
      }]);
    return (
        <Provider store={store} >
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename='/app' history={history}>
                    <Theme>
                        <Layout />
                    </Theme>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App
