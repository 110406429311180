import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiGuestSession, apiSignIn, apiSignOut, apiSignUp ,apiExtAuth} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            
            const resp = await apiSignIn(values)
            if (resp.data) {
                
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    if(resp.data.onboarding && resp.data.onboarding.surveyPending){
                        localStorage.setItem("onboaringsurvey",resp.data.onboarding.surveyPending);
                        localStorage.setItem("onboaringsurveylist",JSON.stringify({}));
                    }
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const tempshopid = localStorage.getItem("tempshopifyid")
                const tempshopifyshop = localStorage.getItem("tempshopifyshop")
                if(!tempshopid){
                    const redirectUrl = query.get(REDIRECT_URL_KEY);
                    const substring = "/dashboard/connect/wix?token";
                    const substringInstance = "/dashboard/stores/wix-auth-callback?instance=";
                    if (redirectUrl && redirectUrl.includes(substring)) {
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    else  if (redirectUrl && redirectUrl.includes(substringInstance)) {
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    else if (redirectUrl && redirectUrl.includes("stores/tiktok-auth-callback")) {
                
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    //window.location.href = redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath;
                    return {
                        status: 'success',
                        message: '',
                    }
                }
                else{
                    const redirectUrl = `/dashboard/stores/shopify-auth-callback?code=${tempshopid}&shop=${tempshopifyshop}`;
                    localStorage.removeItem("tempshopifyid")
                    localStorage.removeItem("tempshopifyshop")
                    // navigate(
                    //     redirectUrl
                    // )
                    window.location.href = redirectUrl;
                    return {
                        status: 'success',
                        message: '',
                    }
                }
               
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const externalAuth = async(values)=>{
        try {
            
            const resp = await apiExtAuth(values)
            if (resp.data) {
                
                const { token } = resp.data
                dispatch(onSignInSuccess(token))

                if (resp.data.user) {
                    
                    if(resp.data.onboarding && resp.data.onboarding.surveyPending){
                        console.log("surveyPending",resp.data.onboarding);
                        

                        localStorage.setItem("onboaringsurvey",resp.data.onboarding.surveyPending);
                        localStorage.setItem("onboaringsurveylist",JSON.stringify({}));
                    }
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
               
                
                const tempshopid = localStorage.getItem("tempshopifyid")
                const tempshopifyshop = localStorage.getItem("tempshopifyshop")
                if(!tempshopid){
                    
                    let redirectUrl = query.get(REDIRECT_URL_KEY);
                    

                    const substring = "/dashboard/connect/wix?token";
                    const substringInstance = "/dashboard/stores/wix-auth-callback?instance=";
                    
                    
                    if (redirectUrl && redirectUrl.includes(substring)) {
                    

                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    else  if (redirectUrl && redirectUrl.includes(substringInstance)) {
                    

                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    else if (redirectUrl && redirectUrl.includes("stores/tiktok-auth-callback")) {
                        
                
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    
                    else{
                        
                        if(resp.data.onboarding.surveyPending){
                        
                            redirectUrl = "/onboarding/survey/welcome"
                        }
                        
                        navigate(
                            
                            
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    
                    return {
                        status: 'success',
                        message: '',
                    }
                }
                else{
                    
                    
                    const redirectUrl = `/dashboard/stores/shopify-auth-callback?code=${tempshopid}&shop=${tempshopifyshop}`;
                    localStorage.removeItem("tempshopifyid")
                    localStorage.removeItem("tempshopifyshop")
                    navigate(
                        redirectUrl
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                }
               
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    if(resp.data.onboarding && resp.data.onboarding.surveyPending){
                        localStorage.setItem("onboaringsurvey",resp.data.onboarding.surveyPending);
                        localStorage.setItem("onboaringsurveylist",JSON.stringify({}));
                    }
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const tempshopid = localStorage.getItem("tempshopifyid")
                const tempshopifyshop = localStorage.getItem("tempshopifyshop")
                if(!tempshopid){
                    let redirectUrl = query.get(REDIRECT_URL_KEY);
                    const substring = "/dashboard/connect/wix?token";
                    const substringInstance = "/dashboard/stores/wix-auth-callback?instance=";
                    if (redirectUrl && redirectUrl.includes(substring)) {
                        localStorage.removeItem("onboaringsurvey")
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    else  if (redirectUrl && redirectUrl.includes(substringInstance)) {
                        localStorage.removeItem("onboaringsurvey")
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    else if (redirectUrl && redirectUrl.includes("stores/tiktok-auth-callback")) {
                        localStorage.removeItem("onboaringsurvey")
                        navigate(
                            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                        )
                    }
                    
                    else{
                        if(resp.data.onboarding.surveyPending){
                        redirectUrl = "/onboarding/survey/welcome"
                    }
                    navigate(
                        redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    )
                }
                    return {
                        status: 'success',
                        message: '',
                    }
                }
                else{
                    const redirectUrl = `/dashboard/stores/shopify-auth-callback?code=${tempshopid}&shop=${tempshopifyshop}`;
                    localStorage.removeItem("tempshopifyid")
                    localStorage.removeItem("tempshopifyshop")
                    navigate(
                        redirectUrl
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                }
               
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const createGuestSession = async()=>{
        const resp = await apiGuestSession();
        if (resp.data) {
            const { token } = resp.data
            dispatch(onSignInSuccess(token))
            if (resp.data.user) {
                dispatch(
                    setUser(
                        resp.data.user || {
                            avatar: '',
                            userName: 'Anonymous',
                            authority: ['USER'],
                            email: '',
                        }
                    )
                )
            }
        
        navigate(
            "/dashboard/product/design"
        )
        return {
            status: 'success',
            message: '',
        }
    }
    }
    const handleSignOut = (path) => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(path?path:appConfig.appHome)
    }

    const signOut = async (path=null) => {
        
        await apiSignOut()
        handleSignOut(path)
    }
    const signOutGuest = async () => {
        
        await apiSignOut()
        handleSignOut("/sign-up")
    }
    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        signOutGuest,
        externalAuth,
        createGuestSession
    }
}

export default useAuth
